import React from "react"

const Footer = ({ siteTitle }) => (
  <footer className="footer">
    <div className="container">
      <div className="row gap-y align-items-center">

        <div className="col-md-6 text-center text-md-left">
          <small>© {`${new Date().getFullYear()} weertelaat.be`}</small>
        </div>

        <div className="col-md-6 text-center text-md-right">
          <div className="social">
            <a className="social-facebook" href="#"><i className="fa fa-facebook"/></a>
            <a className="social-twitter" href="#"><i className="fa fa-twitter"/></a>
            <a className="social-instagram" href="#"><i className="fa fa-instagram"/></a>
            <a className="social-youtube" href="#"><i className="fa fa-youtube"/></a>
          </div>
        </div>

      </div>
    </div>
  </footer>
)

export default Footer
