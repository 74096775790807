import React from "react"
import { Typeahead } from "react-bootstrap-typeahead"
import BelgianStations from "../assets/data-sources/train-stations-belgium"

class RegistrationCardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    alert('A name was submitted: ' + this.state.value);
    event.preventDefault();
  }

  render() {
    return(
      <form className="col-md-12" onSubmit={this.handleSubmit}>
        <div className="card shadow-4">
          <div className="card-body">
            <h5 className="text-left">Tussen welke stations pendel je het meest?</h5>
            <div className="form-row">
              <div className="form-group col-lg-4">
                <Typeahead
                  id={"fromStation"}
                  bsSize={"large"}
                  maxResults={20}
                  placeholder={"Naam vertrek station (*)"}
                  labelKey={option => `${option.name}`}
                  options={BelgianStations}
                />
              </div>
              <div className="form-group col-lg-4">
                <Typeahead
                  id={"fromStation"}
                  bsSize={"large"}
                  maxResults={20}
                  placeholder={"Naam vertrek station (*)"}
                  labelKey={option => `${option.name}`}
                  options={BelgianStations}
                />
              </div>
              <div className="form-group col-lg-4">
                <div className="form-group">
                  <select className="form-control form-control-lg">
                    <option value="home-work">Dagelijks (Woon-werkverkeer)</option>
                    <option value="weekly">Paar keer per week</option>
                    <option value="monthly">Paar keer per maand</option>
                    <option value="yearly">Enkele keren per jaar</option>
                  </select>
                </div>
              </div>
            </div>

            <h5 className="text-left">Met welke gegevens wil je registreren?</h5>
            <div className="form-row">
              <div className="form-group col-lg-2">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Voornaam (*)"
                  name="firstName"
                  value={this.state.value} onChange={this.handleChange}
                />
              </div>
              <div className="form-group col-lg-2">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Achternaam (*)"
                  name="lastName"
                />
              </div>
              <div className="form-group col-lg-5">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Email (*)"
                  name="email"
                />
              </div>
              <div className="form-group col-lg-3">
                <button className="btn btn-block btn-xl btn-primary">
                  Registreren
                </button>
              </div>
            </div>

          </div>
        </div>
      </form>
    )
  }
}
export default RegistrationCardForm
