import React from "react"
import { Link } from "gatsby"
import weerTeLaatLogo from "../../assets/images/weertelaatbe-logo.png"

const Header = ({ siteTitle }) => (
  <>
    <nav className="navbar navbar-expand-lg navbar-dark">
      <div className="container">
        <div className="row">
          <div className="col-8 col-lg-3 navbar-left">
            <button className="navbar-toggler" type="button">
              &#9776;
            </button>
            <Link to={'/'} className="navbar-brand">
              <img className="logo-dark" src={weerTeLaatLogo} alt="logo" />
              <img className="logo-light" src={weerTeLaatLogo} alt="logo" />
            </Link>
          </div>

          <section className="col-lg-6 navbar-mobile">
            <nav className="nav nav-navbar mx-auto">
              <Link to={'/'} className="nav-link">Home</Link>

              <Link to={'contact'} className="nav-link">Contact</Link>
            </nav>
          </section>

          <div className="col-4 col-lg-3 text-right">
            <a
              className="btn btn-sm btn-round btn-outline-danger d-none d-lg-inline-block "
              href="#"
            >
              Inschrijven
            </a>
          </div>
        </div>
      </div>
    </nav>
  </>
)

export default Header
